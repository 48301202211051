[id="HowIWork"] {
    padding: 0 160px 50px;
}

.mainDivHow {
    width: 100%;
    position: relative;
    display: flex;
}

.howRowOne {
    width: 20%;
    background-color: whitesmoke;
    display: flex;
    flex-flow: column wrap;
    position: relative;
}

.howRowOne img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.Up {
    position: relative;
    z-index: 2;
}

.howRow {
    display: flex;
    flex-flow: column wrap;
}

.stepsImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.stepsImage {
    width: 200px;
    height: 150px;
    flex-grow: 1;
    padding-right: 20px;
    min-width: 20%;
}

.stepsSiteDo {
    display: flex;
    flex-flow: row nowrap;
    margin: 40px 20px;
}

.stepsSiteDo:first-of-type {
    margin-top: 0;
}

.stepsSiteDo:last-of-type {
    margin-bottom: 30px;
}

.stepsSiteDo:nth-of-type(even) {
    flex-flow: row-reverse nowrap;
}

.stepsContent {
    width: 100%;
}

.stepsContent h3 {
    text-transform: uppercase;
    font-size: 14px;
    position: relative;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 5px;
}

.stepsContent p {
    font-size: 14px;
    letter-spacing: 0.5px;
    padding-right: 20px;
}

@media (min-width: 280px) and (max-width: 768px) {
    .stepsImage {
        width: 100%;
        height: 200px;
        margin-bottom: 15px;
        padding: 0;
    }
    .stepsContent {
        text-align: center;
    }
    .stepsContent p {
        padding-right: 0;
    }
    .stepsSiteDo {
        flex-flow: column nowrap !important;
        justify-content: start;
        margin: 30px 20px;
    }
    .someWantSite .someWantSiteItemContent {
        width: calc(100% - 40px);
    }
}