.mainFooterClass {
    padding: 50px 185px;
    width: calc(100% - 370px);
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.mainFooterClass>div {
    margin: auto 0;
}

.copyright p {
    color: #a5a5a5;
    font-size: 13px;
}

.socialIcons>div {
    margin-top: 0;
}

.socMedPosition>a {
    font-size: 10px;
}

@media (max-width: 768px) {
    .mainFooterClass {
        padding: 25px 185px;
        flex-flow: row wrap;
    }
    .mainFooterClass>div {
        margin: auto;
    }
}

@media (max-width: 450px) {
    .mainFooterClass {
        padding: 25px 50px;
        width: calc(100% - 100px);
        flex-flow: row wrap;
    }
    .socialIcons>div {
        margin-top: 10px;
    }
    .socMedPosition>a {
        font-size: 13px;
    }
}