[id="contact"] {
    padding: 0 185px 50px;
}

.contactBoxInfo {
    width: auto;
}

.mainContacntDiv {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.mainContacntDiv>div {
    width: 50%;
}

.contactInfoItem svg {
    margin-bottom: 10px;
}

.contactInfoItem {
    margin-bottom: 20px;
}

.contactInfoItem h3 {
    text-align: left;
    margin-bottom: 0px;
    font-size: 16px;
    letter-spacing: 1.5px;
    margin-top: 0;
}

.contactBoxInfo p {
    font-family: "Raleway", sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.6;
    color: #717171;
    margin-top: 5px;
}

.contactBoxInfo a {
    font-family: "Raleway", sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.6;
    color: #00815F;
    margin-top: 5px;
}

.contactInput,
.contactLabel {
    width: 100%;
    display: block;
}

.contactLabel {
    letter-spacing: 1.5px;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 14px;
    font-family: 'Raleway', sans-serif;
    line-height: 40px;
}

.contactButtonSubmit {
    background-color: #00815F;
    color: #ffffff;
    border: 1px solid #cccccc;
    float: right;
    height: 40px;
    width: 110px;
    text-indent: 3px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer;
}

.contactInput {
    background-color: #fafafa;
    border: 1px solid #cccccc;
    color: #1c1c1c;
    height: 40px;
    text-indent: 3px;
}

@media (min-width: 280px) and (max-width: 768px) {
    .mainContacntDiv {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }
    .mainContacntDiv>div {
        width: 100% !important;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    .mainContacntDiv>div>form {
        width: 100% !important;
        margin-top: 20px;
    }
    .contactInfoItem {
        margin: 0px !important;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        min-width: calc(100% / 3);
        flex-grow: 1;
        padding-bottom: 20px;
    }
}