.socialMedia {
    width: max-content;
    margin: 0 auto;
    margin-top: 15px;
}

.socialMedia a {
    padding: 5px;
    padding-top: 15px;
}



@media (max-width: 450px) {
    .socialMedia a {
        padding: 15px 12px 15px;
    }    
}