[id="portfolio"] {
    padding: 0 185px 50px;
}

a {
    cursor: pointer;
}

.mainDiv {
    height: auto;
}

.portfFiltr ul {
    width: max-content;
    margin: 0 auto;
}

.portfFiltr li {
    text-transform: uppercase;
    display: inline-block;
    color: white;
    text-align: center;
    padding: 0 10px;
    cursor: pointer;
}

.portfFiltr li>p {
    width: max-content;
    margin: 0 auto;
    padding: 0 10px;
}

.active {
    padding-bottom: 5px !important;
}

.active>p {
    border-bottom: 2px solid #00815F;
}

.portfItems {
    width: 100%;
    margin: 50px 0;
    margin-bottom: 25px;
    display: flex;
    flex-flow: row wrap;
}

.portfItem {
    position: relative;
    width: 25%;
    height: 220px;
    background-size: cover;
    background-position: top center;
    transition: 0.5s ease;
    flex-grow: 1;
    flex-basis: 25%;
}

.portfItem:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    transition: 0.5s ease;
}

.portfItem:hover:after {
    background: rgba(0, 0, 0, .8);
    transition: 0.5s ease;
}

.hiddenText {
    position: relative;
    transition: 0.5s ease;
    opacity: 0;
    z-index: 6;
    top: 50%;
    margin-top: -50px;
}

.hiddenText h3 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    margin-top: 0;
    letter-spacing: 3px;
    color: #fff;
    margin-bottom: 0;
    padding-bottom: 3px;
}

.hiddenText p {
    text-align: center;
    color: #888;
    box-sizing: border-box;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.6;
    padding-bottom: 6px;
}

.hiddenText button {
    background-color: transparent;
    border: #fff 1px solid;
    padding: 2px 10px;
    display: inline-block;
    border-color: #00815F;
    color: #00a87b;
    letter-spacing: 1px;
    cursor: pointer;
    text-align: center;
    margin: 0 calc(50% - 35px);
}

.portfItem:hover>.hiddenText {
    transition: 0.5s ease;
    opacity: 1;
}

@media (max-width: 1440px) {
    .portfItem {
        flex-basis: 270px;
    }
}

@media (max-width: 768px) {
    .portfFiltr ul {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
    }
    .portfFiltr li {
        width: 100%;
        padding-bottom: 10px;
    }
    .active {
        margin-bottom: 5px;
    }
}

.mainWindowModal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .8);
    z-index: 7;
    overflow: auto;
}

.modalWindow {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 15vh;
    display: block;
}

.modalWindow img {
    width: 100%;
    object-fit: contain;
}

.modalDescCont {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 15px;
}

.modalDescCont h3 {
    color: black !important;
    font-size: 24px;
    line-height: 20px;
    margin-bottom: 5px;
}

.modalExit button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 24px;
    line-height: 20px;
    height: 20px;
}

.modalDescCont p {
    color: black;
}