header {
    position: relative;
}

.parallaxMain::before {
    content: '';
    position: absolute;
    /* background-color: rgba(0, 0, 0, 0.1); */
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 6;
}

.parallaxMain {
    visibility: visible;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-image: url(../../resources/img/bg.jpg);
    /* background-image: url(https://picsum.photos/1920/1080); */
    background-position-x: 50%;
    position: relative;
}

.parallaxContentDiv {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.parallaxContent {
    width: max-content;
    height: auto;
    position: relative;
    z-index: 6;
}

.parallaxContent h1 {
    padding: 10px 35px;
    color: #fff;
    border: 8px solid #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 38px;
    background-color: rgba(0, 0, 0, 0.1);
    font-family: "Raleway", sans-serif;
    line-height: 1.6;
    margin: 20px auto;
    text-align: center;
    width: max-content;
}

.parallaxContent p {
    text-transform: uppercase;
    color: white;
    font-family: "Raleway", sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: 3px;
    text-align: center;
    padding: 0 5px;
}

.hamburger {
    display: none !important;
}

.topNav img,
.scrolledMenu img {
    display: inline-block;
    width: 80px;
    height: 80px;
}

@media (min-width: 1024px) {
    .topNav {
        transition: 0.5s ease;
        position: fixed;
        padding: 3px 20px;
        width: calc(100% - 40px);
        z-index: 9;
    }
    .topNav .navbar,
    .scrolledMenu .navbar {
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .navbar nav {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
    }
    .topNav .navbar nav>a {
        text-transform: uppercase;
        color: white;
        font-family: "Raleway", sans-serif;
        letter-spacing: 1.5px;
        font-size: 16px;
        line-height: 1.6;
        letter-spacing: 1px;
        text-decoration: none;
        text-align: center;
        padding: 12px;
        margin: 0;
        transition: 0.5s ease;
    }
    .scrolledMenu .navbar nav>a {
        text-transform: uppercase;
        color: white;
        font-family: "Raleway", sans-serif;
        letter-spacing: 1.5px;
        font-size: 16px;
        line-height: 1.6;
        letter-spacing: 1px;
        text-decoration: none;
        text-align: center;
        padding: 12px;
        margin: 0;
        transition: 0.5s ease;
    }
    .topNav .navbar nav>a:hover {
        transition: 0.5s ease;
        background: rgba(0, 0, 0, 0.3);
    }
    .scrolledMenu .navbar nav>a:hover {
        transition: 0.5s ease;
        background: rgba(0, 0, 0, 0.4);
    }
    .scrolledMenu {
        transition: 0.5s ease;
        background-color: rgba(0, 0, 0, .55);
        padding: 3px 20px;
        position: fixed;
        z-index: 9;
        width: calc(100% - 40px);
    }
    .topNav .hamburger>span>span,
    .topNav .hamburger>span>span::before,
    .topNav .hamburger>span>span::after {
        background-color: rgb(153, 147, 147) !important;
    }
    .scrolledMenu .hamburger>span>span,
    .scrolledMenu .hamburger>span>span::before,
    .scrolledMenu .hamburger>span>span::after {
        background-color: #000 !important;
    }
}

@media (min-width: 280px) and (max-width: 1023px) {
    .hamburger {
        display: inline-block !important;
        position: fixed;
        top: 20px;
        right: 20px;
        background-color: black !important;
        padding: 10px !important;
        padding-top: 15px !important;
        z-index: 9;
    }
    .topNav img {
        padding: 10px;
        position: relative;
        z-index: 6;
    }
    .navbar {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.95);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 8;
    }
    .parallaxContentDiv {
        top: -12%;
    }
    nav {
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: column wrap;
        transform: translate(0, 40%);
    }
    nav>a {
        width: 100%;
        color: white;
        font-size: 14px;
        font-weight: lighter;
        text-transform: uppercase;
        letter-spacing: 5px;
        text-decoration: none;
        padding: 15px 0;
        text-align: center;
        transition: .4s ease;
    }
    nav>a:hover {
        background-color: rgba(255, 255, 255, 0.1);
        transition: .4s ease;
        color: seagreen;
    }
    .hamburger>span>span,
    .hamburger>span>span::before,
    .hamburger>span>span::after {
        background-color: white !important;
    }
}

@media (max-width: 768px) {
    .parallaxMain {
        background-image: url(../../resources/img/bg2.jpg) !important;
    }   
}

@media (max-width: 540px) {
    .parallaxMain {
        background-image: url(../../resources/img/bg3.jpg) !important;
    }
    
}

@media (max-width: 450px) {
    .parallaxContent h1 {
        font-size: 24px;
        padding: 10px 25px;
    }
    .parallaxContent p {
        font-size: 14px;
    }
    
}