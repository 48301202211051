:root {
    /* for white */
    --whiteBg: white;
    --whiteFontColor: black;
    /* for silver */
    --silverBg: #FAFAFA;
    --silverFontColor: black;
    /* for dark */
    --darkBg: #222;
    --darkFontColor: white;
}


/* white */

.whiteTheme {
    background-color: var(--whiteBg);
}

.whiteTheme p,
.whiteTheme h2,
.whiteTheme h3,
.whiteTheme label,
.whiteTheme hr {
    color: var(--whiteFontColor);
}


/* silver */

.silverTheme {
    background-color: var(--silverBg);
}

.silverTheme p,
.silverTheme h2,
.silverTheme h3,
.silverTheme label,
.silverTheme hr {
    color: var(--silverFontColor);
}


/* dark */

.darkTheme {
    background-color: var(--darkBg);
}

.darkTheme p,
.darkTheme h2,
.darkTheme h3,
.darkTheme label,
.darkTheme hr {
    color: var(--darkFontColor);
}