.aboutContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.mainDivAb {
    background-color: #FAFAFA;
}

.mainImage {
    width: 30%;
    margin: 0 auto;
}

.meImage {
    width: 100%;
    display: inline-block;
    height: 23vw;
    margin: 0 auto;
}

.meImage img {
    width: auto;
    height: 100%;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
}

.aboutText {
    width: calc(62% - 40px);
    display: inline-block;
    position: relative;
    padding-bottom: 40px;
}

.aboutText p {
    font-size: 16px;
    margin-bottom: 10px;
}

.aboutText h3 {
    font-size: 16px;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 10px;
    color: black;
    letter-spacing: 1.5px;
    font-weight: bold;
    text-transform: uppercase;
}

.aboutText span, .aboutText a {
    font-weight: bold;
    color: #00815F;
    font-size: 16px;
}

@media (max-width: 1440px) {
    .aboutContent {
        flex-flow: row wrap;
    }
    .meImage,
    .mainImage,
    .aboutText {
        width: 100%;
    }
    .meImage {
        height: 30vw;
    }
    .socialIconsAbout {
        margin: 15px auto !important;
    }
}

@media (max-width: 1024px) {
    .meImage {
        height: 35vw;
    }
}

@media (max-width: 768px) {
    .meImage {
        height: 45vw;
    }
    .aboutText {
        padding-bottom: 0;
    }
}

@media (max-width: 450px) {
    .meImage {
        height: 70vw;
    }
}