* {
    margin: 0;
    padding: 0;
    font-family: "Raleway", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.6;
}

section {
    padding: 0 100px;
    padding-bottom: 50px;
    border-bottom: 1px solid #eee;
}

header,
footer {
    padding: 0 !important;
}

.sectionHeader {
    padding-top: 50px;
    margin-bottom: 50px;
}

.sectionHeader h2 {
    text-align: center;
    font-size: 24px;
    text-transform: uppercase;
    margin: 0px 0 8px;
    letter-spacing: 1px;
    font-weight: bold;
}

.sectionHeader p {
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    position: relative;
    font-weight: 600;
    letter-spacing: 3px;
}

.sectionHeader hr {
    width: 150px;
    margin: 0 auto;
    margin-top: 18px;
}


/* loader start */

.loader {
    background: none repeat scroll 0 0 #ffffff;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: .5s ease;
}

.hide {
    opacity: 0;
    transition: 1s ease;
    z-index: -10;
}

.loader .loader_inner {
    background-image: url("../resources/img/preloader.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #fff;
    height: 60px;
    width: 60px;
    margin-top: -30px;
    margin-left: -30px;
    left: 50%;
    top: 50%;
    position: absolute;
}


/* loader stop */

.bg_black {
    background-color: #222;
    padding-top: 50px;
}

.bg_black p,
.bg_black h2,
.bg_black hr {
    color: white;
}

@media (max-width: 1440px) {
    section {
        padding: 0 50px !important;
        padding-bottom: 50px;
    }
}

@media (max-width: 768px) {
    .sectionHeader {
        padding-top: 25px;
        margin-bottom: 30px;
    }
    section {
        padding: 0 25px !important;
        padding-bottom: 25px;
    }
    .bg_black {
        padding-top: 25px;
    }
}